import React, { useState, useEffect } from "react";
import {
	Box,
	CircularProgress,
	Modal,
	Typography,
	useTheme,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import toast from "react-hot-toast";
import { createOrUpdateLoanOffer } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";
import { useDropzone } from "react-dropzone";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

interface LoanOfferModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
	client?: any;
	type?: string;
	setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const LoanOfferModal: React.FC<LoanOfferModalProps> = ({
	open,
	onClose,
	selectedApplication,
	client,
	type = "offer",
	setTabIndex,
}) => {
	const queryClient = useQueryClient();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);

	const [startDate, setStartDate] = useState<moment.Moment | null>(
		selectedApplication?.startDate
			? moment(selectedApplication.startDate, "DD/MM/YYYY")
			: null
	);

	const [endDate, setEndDate] = useState<moment.Moment | null>(
		selectedApplication?.endDate
			? moment(selectedApplication.endDate, "DD/MM/YYYY")
			: null
	);

	const [formData, setFormData] = useState({
		approvedFundingAmount: 0,
		approvedDuration: 0,
		interestRate: 0,
		originationFee: 0,
		repaymentTerms: "",

		loanStatus: "Approved",
		lendingApplicationId:
			type == "offer"
				? selectedApplication?.lendingApplicationId
				: selectedApplication?.id,
		id:
			type == "offer"
				? selectedApplication?.id
				: selectedApplication?.loanOfferId,
		serviceFee: 0,
		total: 0,
		uploadedFileName: "",
	});

	const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

	const calculateServiceFee = (
		fundingAmount: number,
		interestRate: number,
		duration: number
	) => {
		const dailyInterest = interestRate / 100.0;
		const interestCharge = parseFloat(
			((Math.pow(1 + dailyInterest, duration) - 1) * 100).toFixed(2)
		);

		const calculatedFee = (interestCharge / 100.0) * fundingAmount;

		return calculatedFee < 500 ? 500 : calculatedFee;
	};

	const calculateTotal = (
		fundingAmount: number,
		originationFee: number,
		serviceFee: number
	) => Number(fundingAmount) + Number(originationFee) + Number(serviceFee);

	useEffect(() => {
		if (selectedApplication) {
			const serviceFee = calculateServiceFee(
				selectedApplication.approvedFundingAmount || 0,
				selectedApplication.interestRate || 0,
				selectedApplication.approvedDuration || 0
			);

			setStartDate(
				selectedApplication?.startDate
					? moment(selectedApplication.startDate, "DD/MM/YYYY")
					: null
			);

			setEndDate(
				selectedApplication?.endDate
					? moment(selectedApplication.endDate, "DD/MM/YYYY")
					: null
			);

			setFormData({
				approvedFundingAmount: selectedApplication.approvedFundingAmount || 0,
				approvedDuration: selectedApplication.approvedDuration || 0,
				interestRate: selectedApplication.interestRate || 0,
				originationFee: selectedApplication.originationFee || 0,
				repaymentTerms: selectedApplication.repaymentTerms || "",

				loanStatus: "Approved",
				lendingApplicationId:
					type == "offer"
						? selectedApplication?.lendingApplicationId
						: selectedApplication?.id,
				id:
					type == "offer"
						? selectedApplication?.id
						: selectedApplication?.loanOfferId,
				serviceFee,
				total: calculateTotal(
					selectedApplication.approvedFundingAmount || 0,
					selectedApplication.originationFee || 0,
					serviceFee
				),
				uploadedFileName: selectedApplication.uploadedFileName || "",
			});
		}
	}, [selectedApplication]);

	type FormDataKey = keyof typeof formData;

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => {
			const updatedForm = {
				...prevFormData,
				[name as FormDataKey]: value,
			};
			return {
				...updatedForm,
				serviceFee: calculateServiceFee(
					Number(updatedForm.approvedFundingAmount),
					Number(updatedForm.interestRate),
					Number(updatedForm.approvedDuration)
				),
				total: calculateTotal(
					updatedForm.approvedFundingAmount,
					updatedForm.originationFee,
					updatedForm.serviceFee
				),
			};
		});
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving loan offer...");

		try {
			const payload = {
				...formData,
				startDate: startDate ? startDate.format("DD/MM/YYYY") : "",
				endDate: endDate ? endDate.format("DD/MM/YYYY") : "",
			};

			const response = await createOrUpdateLoanOffer(payload);
			toast.success(response.data.message, { id: toastId });
			onClose();
			queryClient.invalidateQueries("loanOffers");
			queryClient.invalidateQueries("loanOffersSummary");
			setSelectedFiles([]);
			if (setTabIndex) setTabIndex(1);
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					position: "relative",
					overflow: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
				}}
			>
				<ResponsiveGrid columnCount={2}>
					<BaseCard sx={{ padding: "20px", height: "auto" }}>
						<Box component="form">
							<CustomCardHeader title="Loan Offer Details" />

							<CustomTextInput
								name="approvedFundingAmount"
								label="Approved Funding Amount (Rands)"
								type="number"
								value={formData.approvedFundingAmount}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="approvedDuration"
								label="Approved Duration (Days)"
								type="number"
								value={formData.approvedDuration}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="interestRate"
								label="Interest Rate (%)"
								type="number"
								value={formData.interestRate}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="originationFee"
								label="Origination Fee (Rands)"
								type="number"
								value={formData.originationFee}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="repaymentTerms"
								label="Repayment Terms"
								type="text"
								value={formData.repaymentTerms}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>
							<Box display="flex" gap={2} width="100%" my={2}>
								<LocalizationProvider dateAdapter={AdapterMoment}>
									<DatePicker
										label="Start Date"
										value={startDate}
										onChange={(newValue) => setStartDate(newValue)}
										minDate={moment()}
										sx={{ width: "100%" }}
									/>
									<DatePicker
										label="End Date"
										value={endDate}
										onChange={(newValue) => setEndDate(newValue)}
										minDate={moment()}
										sx={{ width: "100%" }}
									/>
								</LocalizationProvider>
							</Box>
						</Box>
					</BaseCard>

					<BaseCard sx={{ padding: "20px", height: "auto" }}>
						<Box component="form" mt={2}>
							<CustomCardHeader title="Loan Offer Calculator" />

							<CustomTextInput
								name="approvedFundingAmount"
								label="Approved Funding Amount (Rands)"
								type="number"
								value={formData.approvedFundingAmount}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="originationFee"
								label="Origination Fee (Rands)"
								type="number"
								value={formData.originationFee}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="serviceFee"
								label="Service Fee (Rands)"
								type="number"
								value={
									formData.serviceFee
										? Number(formData.serviceFee.toFixed(2))
										: 0
								}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="interestRate"
								label="Interest Rate (%)"
								type="number"
								value={formData.interestRate}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<CustomTextInput
								name="approvedDuration"
								label="Duration (Days)"
								type="number"
								value={formData.approvedDuration}
								onChange={handleInputChange}
								sx={{ mb: 2 }}
							/>

							<Box display="flex" justifyContent="space-between" mt={4}>
								<Box>
									<CustomButton text="Calculate" variant="contained" />
								</Box>

								<Box>Total: R {formData.total.toLocaleString()}</Box>
							</Box>
						</Box>
					</BaseCard>
				</ResponsiveGrid>

				<Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
					<CustomButton text="Cancel" variant="outlined" onClick={onClose} />
					<CustomButton
						text="Save Loan Offer"
						variant="contained"
						onClick={handleSubmit}
						disabled={isLoading}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default LoanOfferModal;
