import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ComponentState {
  componentIds: string[];
}

const initialState: ComponentState = {
  componentIds: [],
};

const componentSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    addComponentId(state, action: PayloadAction<string>) {
      if (!state.componentIds.includes(action.payload)) {
        state.componentIds.push(action.payload);
      }
    },
    removeComponentId(state, action: PayloadAction<string>) {
      state.componentIds = state.componentIds.filter((id) => id !== action.payload);
    },
    clearComponentIds(state) {
      state.componentIds = [];
    },
  },
});

export const { addComponentId, removeComponentId, clearComponentIds } = componentSlice.actions;
export default componentSlice.reducer;
