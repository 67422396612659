import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import {
	CloudUpload,
	Delete,
	Visibility,
	Label,
	LinkOutlined,
} from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";
import { CircularProgress, Grid, Tooltip, useTheme } from "@mui/material";
import IconWithText from "../../common/components/Elements/IconWithText";
import { useLocation, useNavigate } from "react-router-dom";
import TabsWithSearch from "../../common/components/Widgets/TabsWithSearch";
import financialDummyData from "../../data/financialData";
import BankStatementUploadModal from "../../common/components/Modals/BankStatementsUploadModal";
import LinkBankAccountModal from "../../common/components/Modals/LinkBankAccountModal";
import ManageLabellingRulesModal from "../../common/components/Modals/ManageLabellingRulesModal";
import FinancialDataModal from "../../common/components/Modals/FinancialDataModal";
import { transactionHistoriesDummyData } from "../../data/transactionHistories";
import { uploadedStatementsDummyData } from "../../data/uploadedStatements";
import {
	getAllUploadedBankStatements,
	getTransactionHistories,
	getUploadedBankStatements,
} from "../../common/services/retool-service";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const FinancialDataPage: React.FC = () => {
	const [openBankStatementsUploadModal, setOpenBankStatementsUploadModal] =
		useState(false);
	const [openLinkBankAccountModal, setOpenLinkBankAccountModal] =
		useState(false);
	const [openManageLabellingRulesModal, setOpenManageLabellingRulesModal] =
		useState(false);
	const [openViewFinancialDataModal, setOpenViewFinancialDataModal] =
		useState(false);

	const [selectedTab, setSelectedTab] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const userId = useSelector((state: RootState) => state.auth.user?.id);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [selectedStatements, setSelectedStatements] = useState<any[]>([]);
	const [selectedTransactions, setSelectedTransactions] = useState<any[]>([]);

	const navigate = useNavigate();
	const theme = useTheme();

	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [data, setData] = useState<any[]>([]);

	const queryClient = useQueryClient();

	const { data: uploadedBankStatementsData, isLoading: isLoadingStatements } =
		useQuery(
			["uploadedBankStatements", userId],
			() => getUploadedBankStatements(userId),
			{
				enabled: !!userId,
				select: (data) => data?.data?.data || [],
				staleTime: Infinity,
			}
		);

	const {
		data: allUploadedBankStatementsData,
		isLoading: isLoadingAllStatements,
		isRefetching,
		refetch,
	} = useQuery(
		["allUploadedBankStatements"],
		() => getAllUploadedBankStatements(),
		{
			select: (data) => data?.data?.data || [],
		}
	);

	const refetchStatements = () => {
		refetch();
	};

	const { data: transactionHistoriesData, isLoading: isLoadingHistories } =
		useQuery(
			["transactionHistories", userId],
			() => getTransactionHistories(userId),
			{
				enabled: !!userId,
				select: (data) => data?.data?.data || [],
			}
		);

	const getFilteredData = (
		statusFilter: string = tabs[selectedTab].status
	): any[] => {
		if (
			!allUploadedBankStatementsData ||
			allUploadedBankStatementsData.length === 0
		)
			return [];

		allUploadedBankStatementsData.forEach((each: any) => {
			each.name = each.documentName;
			each.document = each.ocrStatusValue === "Succeeded" ? "1/1" : "0/1";
			each.type = "OCR";
			each.description =
				each.description == null || each.description == undefined
					? "None"
					: each.description;
			each.status =
				each.ocrStatusValue === "Succeeded"
					? "Completed"
					: each.ocrStatusValue !== "Failed"
						? "Pending"
						: "Failed";
			each.dateCreated = moment(each.uploadedTimestamp).format("YYYY-MM-DD");
		});

		setSelectedRows([...allUploadedBankStatementsData]);

		const groupedData = allUploadedBankStatementsData.reduce(
			(acc: any, curr: any) => {
				const userId = curr.userId;
				const email = curr.email;

				if (!acc[userId]) {
					acc[userId] = {
						userId,
						name: [],
						document: { succeeded: 0, total: 0 },
						status: [],
						description: "None",
						dateCreated: curr.dateCreated,
						email,
					};
				}

				acc[userId].name.push(curr.name);
				acc[userId].document.total += 1;
				if (curr.ocrStatusValue === "Succeeded") {
					acc[userId].document.succeeded += 1;
				}

				acc[userId].status.push(curr.status);

				if (acc[userId].description === "None" && curr.description !== "None") {
					acc[userId].description = curr.description;
				}

				acc[userId].dateCreated = moment
					.max(
						moment(acc[userId].dateCreated, "YYYY-MM-DD"),
						moment(curr.dateCreated, "YYYY-MM-DD")
					)
					.format("YYYY-MM-DD");

				return acc;
			},
			{}
		);

		const finalData = Object.values(groupedData).map((group: any) => ({
			userId: group.userId,
			name: group.name.join(", "),
			document: `${group.document.succeeded}/${group.document.total}`,
			type: "OCR",
			description: group.description,
			status: group.status[0],
			dateCreated: group.dateCreated,
			email: group.email,
		}));

		let filteredData = finalData.filter((item: any) => {
			const lowerCaseSearchTerm = searchTerm.toLowerCase();
			return (
				item.name?.toLowerCase().includes(lowerCaseSearchTerm) ||
				item.status?.toLowerCase().includes(lowerCaseSearchTerm) ||
				item.type?.toLowerCase().includes(lowerCaseSearchTerm) ||
				item.description?.toLowerCase().includes(lowerCaseSearchTerm)
			);
		});

		setData([...filteredData]);

		if (statusFilter && statusFilter !== "All") {
			filteredData = filteredData.filter(
				(item: any) => item.status === statusFilter
			);
		}

		return filteredData;
	};

	const tabs = [
		{ label: `All (${data.length})`, status: "All" },
		{
			label: `Completed (${data.filter((x) => x.status === "Completed").length})`,
			status: "Completed",
		},
		{
			label: `Pending (${data.filter((x) => x.status === "Pending").length})`,
			status: "Pending",
		},
		{
			label: `Failed (${data.filter((x) => x.status === "Failed").length})`,
			status: "Failed",
		},
	];

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleView = (row: any) => {
		setOpenViewFinancialDataModal(true);
		setSelectedRow(row);

		const filteredUploadedBankStatements = selectedRows.filter(
			(x) => x.userId === row.userId
		);

		setSelectedStatements(filteredUploadedBankStatements);

		setSelectedTransactions(
			filteredUploadedBankStatements.flatMap(
				(statement: any) => statement.items
			)
		);
	};

	useEffect(() => {
		if (allUploadedBankStatementsData) {
			const newFilteredData = getFilteredData();
			setFilteredData(newFilteredData);
		}
	}, [allUploadedBankStatementsData, searchTerm, selectedTab]);

	const location = useLocation();

	useEffect(() => {
		refetch();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<GreetingMessage title="Manage Financial Data" />
			<Grid container spacing={1} justifyContent="flex-start" width="100%">
				<Grid
					sx={{ cursor: "pointer" }}
					item
					xs={12}
					lg={3.5}
					onClick={() => setOpenManageLabellingRulesModal(true)}
				>
					<BaseCard sx={{ height: "auto", padding: "10px" }}>
						<IconWithText
							icon={
								<Label
									style={{
										color: "#C7C000",
										marginRight: "5px",
										fontSize: "34px",
									}}
								/>
							}
							besideText="Manage labelling rules"
						/>
					</BaseCard>
				</Grid>
				<Grid
					sx={{ cursor: "pointer" }}
					item
					xs={12}
					lg={3.5}
					onClick={() => setOpenBankStatementsUploadModal(true)}
				>
					<BaseCard sx={{ height: "auto", padding: "10px" }}>
						<IconWithText
							icon={
								<CloudUpload
									style={{
										color: "teal",
										marginRight: "5px",
										fontSize: "34px",
									}}
								/>
							}
							besideText="Upload bank statements"
						/>
					</BaseCard>
				</Grid>
			</Grid>
			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={4}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						width="100%"
						p={2}
					>
						<GreetingMessage title="Financial Data" />
						<LoadingIconButton onClick={refetch} isLoading={isRefetching} />
					</Box>
					<TabsWithSearch
						tabs={tabs}
						selectedTab={selectedTab}
						onTabChange={handleTabChange}
						searchTerm={searchTerm}
						onSearchChange={handleSearchChange}
					/>
					<CustomTable
						isLoading={isLoadingAllStatements}
						icons={(row) => [
							{
								icon: (
									<Tooltip title="View Details" arrow>
										<Visibility
											sx={{
												fill:
													theme.palette.mode === "light"
														? "rgba(73, 69, 79, 1)"
														: "white",
												fontSize: "20px",
											}}
										/>
									</Tooltip>
								),
								onClick: () => handleView(row),
							},
						]}
						columns={[
							{ title: "Email", key: "email", sortable: true },
							{ title: "Document", key: "document", sortable: true },
							{ title: "Date Created", key: "dateCreated", sortable: true },
							{ title: "Description", key: "description", sortable: true },
							{ title: "Type", key: "type", sortable: true, isPill: true },
							{ title: "Status", key: "status", sortable: true },
						]}
						data={filteredData}
						actionColumn={{
							title: "Actions",
						}}
					/>
				</Box>
			</BaseCard>

			<FinancialDataModal
				open={openViewFinancialDataModal}
				onClose={() => setOpenViewFinancialDataModal(false)}
				transactionHistories={selectedTransactions}
				uploadedStatements={selectedStatements}
				type={selectedRow?.type || ""}
				isFetchingTransactionHistories={isLoadingHistories}
				isFetchingUploadedStatements={isLoadingStatements}
			/>

			<BankStatementUploadModal
				handleRefetch={refetchStatements}
				open={openBankStatementsUploadModal}
				onClose={() => setOpenBankStatementsUploadModal(false)}
			/>
			<LinkBankAccountModal
				open={openLinkBankAccountModal}
				onClose={() => setOpenLinkBankAccountModal(false)}
			/>
			<ManageLabellingRulesModal
				open={openManageLabellingRulesModal}
				onClose={() => setOpenManageLabellingRulesModal(false)}
			/>
		</Box>
	);
};

export default FinancialDataPage;
