import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Chip,
	Switch,
	TableSortLabel,
	IconButton,
	Checkbox,
	FormControlLabel,
	Popover,
	TablePagination,
	CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	ArrowBack,
	ArrowForward,
	CheckCircle,
	Error,
	FlagOutlined,
	PriorityHighOutlined,
	FilterList,
	Flag,
	ArrowCircleLeft,
	ArrowCircleRight,
	CloudCircle,
	Cancel,
	HourglassEmpty,
	MarkEmailRead,
} from "@mui/icons-material";

import { parseBoolean } from "../../helpers/utils/booleanUtils";
import {
	Table,
	TableCell,
	TableContainer,
	TableRowStyled,
} from "../Styled/TableComponents";
import ToggleSwitch from "../Elements/CustomSwitch";
import CustomTableHead from "./CustomTableHead";
import CustomTableBody from "./CustomTableBody";
import toast from "react-hot-toast";

interface TableColumn {
	title: string;
	key: string;
	icon?: React.ReactNode;
	sortable?: boolean;
	isSwitch?: boolean;
	isPill?: boolean;
	withSignFormating?: boolean;
	handler?: (row: any, value?: any) => void;
	isDecimal?: boolean;
}

interface TableRow {
	[key: string]: any;
}

interface ActionIcon {
	icon: React.ReactNode;
	onClick: (row: any) => void;
}

interface DynamicTableProps {
	columns: TableColumn[];
	data: TableRow[];
	actionColumn?: {
		title: string;
		icon?: React.ReactNode;
	};
	icons?: (client: any) => ActionIcon[];
	onRowSelect?: (selectedRows: TableRow[]) => void;
	selectable?: boolean;
	isLoading?: boolean;
	selected?: TableRow[];
	count?: number;
}

const CustomTable: React.FC<DynamicTableProps> = ({
	columns,
	data,
	actionColumn,
	icons,
	onRowSelect,
	selectable = false,
	isLoading = false,
	selected,
	count = 10,
}) => {
	const previousValueRef = useRef<string | null>(null);
	const colorMapRef = useRef<{
		[key: string]: "primary" | "secondary" | "error" | "success";
	}>({});

	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: "asc" | "desc";
	} | null>(null);
	const [riskFilter, setRiskFilter] = useState<{
		Low: boolean;
		Medium: boolean;
		High: boolean;
	}>({
		Low: true,
		Medium: true,
		High: true,
	});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(count);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedRows, setSelectedRows] = useState<TableRow[]>(
		selected ? selected : []
	);

	const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleFilterClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleSort = (key: string) => {
		let direction: "asc" | "desc" = "asc";
		if (
			sortConfig &&
			sortConfig.key === key &&
			sortConfig.direction === "asc"
		) {
			direction = "desc";
		}
		setSortConfig({ key, direction });
	};

	const handleRiskFilterChange = (riskLevel: "Low" | "Medium" | "High") => {
		setRiskFilter((prev) => ({
			...prev,
			[riskLevel]: !prev[riskLevel],
		}));
	};

	const handleRowSelection = (row: TableRow) => {
		const newSelectedRows = selectedRows.includes(row)
			? selectedRows.filter((selectedRow) => selectedRow !== row)
			: [...selectedRows, row];

		setSelectedRows(newSelectedRows);
		if (onRowSelect) {
			onRowSelect(newSelectedRows);
		}
	};

	const sortedAndFilteredData = React.useMemo(() => {
		let sortableItems = [...data];

		// Sort the data
		if (sortConfig !== null) {
			sortableItems.sort((a, b) => {
				const aValue = a[sortConfig.key];
				const bValue = b[sortConfig.key];
				if (aValue < bValue) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (aValue > bValue) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}

		// Filter the data only if riskStatus column is present
		if (columns.some((col) => col.key === "riskStatus")) {
			return sortableItems.filter((item) =>
				Object.keys(riskFilter).some(
					(key) =>
						riskFilter[key as keyof typeof riskFilter] &&
						item.riskStatus === key
				)
			);
		}

		return sortableItems;
	}, [data, sortConfig, riskFilter, columns]);

	const paginatedData = sortedAndFilteredData.slice(
		page * rowsPerPage,
		page * rowsPerPage + rowsPerPage
	);

	const renderCellValue = (row: any, column: TableColumn) => {
		const key = column.key;
		const value = row[column.key];

		if (typeof value === "boolean" && column.isPill) {
			const parsedValue = value;

			const chipColor = parsedValue ? "success" : "error";

			return (
				<span style={{ display: "flex", alignItems: "center" }}>
					<Chip label={parsedValue ? "Yes" : "No"} color={chipColor} />
				</span>
			);
		}

		if (typeof value == "boolean" || column.isSwitch) {
			const parsedValue = parseBoolean(value);

			return (
				<span style={{ display: "flex", alignItems: "center" }}>
					<ToggleSwitch
						checked={parsedValue}
						onChange={
							column.handler
								? () => column.handler?.(row, parsedValue)
								: undefined
						}
					/>
				</span>
			);
		}

		if (column.isPill) {
			let chipColor: "primary" | "secondary" | "error" | "success";
			let customColor = null;

			switch (value) {
				case "Uploaded":
				case "Yes":
				case "Accepted":
					chipColor = "success";
					break;
				case "Sent":
					chipColor = "primary";
					break;
				case "Pending":
				case "No":
				case "False":
				case "Failed":
				case "Declined":
				case "Rejected":
					chipColor = "error";
					break;
				case "InProgress":
					customColor = { backgroundColor: "#FFCC00", color: "black" };
					break;
				case "Submitted":
					customColor = { backgroundColor: "#1976D2", color: "white" };
					break;
				case "Reviewed":
					customColor = { backgroundColor: "#6A1B9A", color: "white" };
					break;
				case "Approved":
					customColor = { backgroundColor: "#619C9C", color: "white" };
					break;
				case "Declined":
				case "Rejected":
					customColor = { backgroundColor: "#D32F2F", color: "white" };
					break;
				case "Cancelled":
					customColor = { backgroundColor: "#616161", color: "white" };
					break;
				case "Expired":
					customColor = { backgroundColor: "orange", color: "white" };
					break;
				case "Abandoned":
					customColor = { backgroundColor: "#8E24AA", color: "white" };
					break;
				case "PreSubmitted":
					customColor = { backgroundColor: "#0277BD", color: "white" };
					break;
				case "PaidUp":
					customColor = { backgroundColor: "#4CAF50", color: "white" };
					break;
				case "Draft":
					customColor = { backgroundColor: "#BDBDBD", color: "black" };
					break;
				// New Risk Levels
				case "Potential High Risk":
					customColor = { backgroundColor: "#C8102E", color: "white" }; // Dark Red
					break;
				case "Medium Risk":
					customColor = { backgroundColor: "#FFA500", color: "white" }; // Orange
					break;
				case "Low Risk":
					customColor = { backgroundColor: "#4CAF50", color: "white" }; // Green
					break;
				// Score Band Ranges
				case "1 - Extreme Risk":
					customColor = { backgroundColor: "#C8102E", color: "white" }; // Dark Red
					break;
				case "2 - Severe Risk":
					customColor = { backgroundColor: "#D32F2F", color: "white" }; // Red
					break;
				case "3 - Mild Risk":
					customColor = { backgroundColor: "#FFA500", color: "white" }; // Orange
					break;
				case "4 - Moderate Risk":
					customColor = { backgroundColor: "#FFCC00", color: "black" }; // Yellow
					break;
				case "5 - Minimal Risk":
					customColor = { backgroundColor: "#4CAF50", color: "white" }; // Green
					break;
				default:
					if (!colorMapRef.current[value]) {
						const colors: Array<"primary" | "secondary" | "error" | "success"> =
							["primary", "secondary", "error", "success"];
						const usedColors = Object.values(colorMapRef.current) as Array<
							"primary" | "secondary" | "error" | "success"
						>;
						const availableColors = colors.filter(
							(color) => !usedColors.includes(color)
						);

						chipColor =
							availableColors.length > 0 ? availableColors[0] : "primary";
						colorMapRef.current[value] = chipColor;
					} else {
						chipColor = colorMapRef.current[value];
					}
					break;
			}

			previousValueRef.current = value;

			return (
				<span style={{ display: "flex", alignItems: "center" }}>
					<Chip
						label={value}
						color={customColor ? undefined : chipColor}
						sx={customColor ? customColor : undefined}
					/>
				</span>
			);
		}

		if (
			key === "status" &&
			["Completed", "Pending", "Failed", "Sent"].includes(value)
		) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{value === "Pending" && (
						<>
							<HourglassEmpty
								style={{ color: "#FFA500", marginRight: "5px" }}
							/>
							<span style={{ color: "#FFA500" }}>{value}</span>
						</>
					)}

					{value === "Completed" && (
						<>
							<CheckCircle style={{ color: "#28A745", marginRight: "5px" }} />
							<span style={{ color: "#28A745" }}>{value}</span>
						</>
					)}

					{value === "Sent" && (
						<>
							<MarkEmailRead style={{ color: "green", marginRight: "5px" }} />
							<span style={{ color: "#28A745" }}>{value}</span>
						</>
					)}

					{value === "Failed" && (
						<>
							<Cancel style={{ color: "#DC3545", marginRight: "5px" }} />
							<span style={{ color: "#DC3545" }}>{value}</span>
						</>
					)}
				</div>
			);
		}

		if (key === "riskStatus") {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{value === "Low" && (
						<>
							<CheckCircle style={{ color: "green", marginRight: "5px" }} />
							<span>{value}</span>
						</>
					)}
					{value === "Medium" && (
						<>
							<Error style={{ color: "#C7C000", marginRight: "5px" }} />
							<span style={{ color: "#C7C000" }}>{value}</span>
						</>
					)}
					{value === "High" && (
						<>
							<Error style={{ color: "red", marginRight: "5px" }} />
							<span style={{ color: "red" }}>{value}</span>
						</>
					)}
				</div>
			);
		}

		if (key !== "flag" && ["Low", "Medium", "High"].includes(value)) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{value === "Low" && (
						<>
							<CheckCircle style={{ color: "green", marginRight: "5px" }} />
							<span>{value}</span>
						</>
					)}

					{value === "Medium" && (
						<>
							<Error style={{ color: "#C7C000", marginRight: "5px" }} />
							<span style={{ color: "#C7C000" }}>{value}</span>
						</>
					)}

					{value === "High" && (
						<>
							<Error style={{ color: "red", marginRight: "5px" }} />
							<span style={{ color: "red" }}>{value}</span>
						</>
					)}
				</div>
			);
		}

		if (
			Array.isArray(value) &&
			value.every((v) => ["Low", "Medium", "High"].includes(v))
		) {
			return (
				<div style={{ display: "flex", alignItems: "center" }}>
					{value.map((v, index) => (
						<span key={index} style={{ marginRight: "5px" }}>
							{v === "Low" && <Flag style={{ color: "green" }} />}
							{v === "Medium" && <Flag style={{ color: "#C7C000" }} />}
							{v === "High" && <Flag style={{ color: "red" }} />}
						</span>
					))}
				</div>
			);
		}

		if (typeof value != "boolean" && key === "flag") {
			return (
				<span style={{ display: "flex", alignItems: "center" }}>
					<ToggleSwitch
						checked={value === "Low"}
						onChange={column.handler ? () => column.handler?.(row) : undefined}
					/>
				</span>
			);
		}

		if (key.toLowerCase() === "amount" || column.title == "Amount") {
			if (column.withSignFormating) {
				return (
					<Box display="flex" alignItems="center">
						{value < 0 ? (
							<ArrowBack style={{ color: "red" }} />
						) : (
							<ArrowForward style={{ color: "green" }} />
						)}
						<span
							style={{ marginLeft: "4px" }}
						>{`R${Math.abs(value).toLocaleString()}`}</span>
					</Box>
				);
			} else {
				return <span>{`R${value.toLocaleString()}`}</span>;
			}
		}

		if (key === "transactionFlow") {
			return (
				<Box display="flex" alignItems="center">
					{value === "Out" && <ArrowBack style={{ color: "red" }} />}
					{value === "In" && <ArrowForward style={{ color: "green" }} />}
					{value === "Both" && (
						<Box
							sx={{
								position: "relative",
								display: "flex",
								alignItems: "center",
							}}
						>
							<ArrowBack
								style={{
									position: "absolute",
									color: "red",
									left: 0,
									zIndex: 1,
									transform: "translate(-2px, 15px)",
									fontSize: "20px",
								}}
							/>
							<ArrowForward
								style={{
									position: "absolute",
									color: "green",
									right: 0,
									zIndex: 2,
									transform: "translate(25px, 2px)",
									fontSize: "20px",
								}}
							/>
						</Box>
					)}
				</Box>
			);
		}

		if (column.isDecimal === false && !isNaN(value)) {
			return parseInt(value);
		}

		return value ? value : "None";
	};

	const isAllSelected = selectedRows.length === data.length;

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedRows(data);
			if (onRowSelect) {
				onRowSelect(data);
			}
		} else {
			setSelectedRows([]);
			if (onRowSelect) {
				onRowSelect([]);
			}
		}
	};

	useEffect(() => {
		if (selected) setSelectedRows(selected);
	}, [selected]);
	return (
		<>
			{isLoading ? (
				<Box p={12} width="100%" height="100%" textAlign="center">
					<CircularProgress size="70px" color="success" />
				</Box>
			) : (
				<TableContainer sx={{ overflow: "auto" }}>
					<Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
						<Table sx={{ borderCollapse: "collapse" }}>
							<CustomTableHead
								columns={columns}
								selectable={selectable}
								isAllSelected={isAllSelected}
								handleSelectAll={(e: any) => handleSelectAll(e)}
								sortConfig={null}
								handleSort={handleSort}
								handleFilterClick={handleFilterClick}
								riskFilter={riskFilter}
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								id={id}
								actionColumn={actionColumn}
								handleFilterClose={() => setAnchorEl(null)}
								handleRiskFilterChange={handleRiskFilterChange}
							/>
							<CustomTableBody
								paginatedData={paginatedData}
								columns={columns}
								renderCellValue={renderCellValue}
								handleRowSelection={handleRowSelection}
								selectedRows={selectedRows}
								selectable={selectable}
								actionColumn={actionColumn}
								icons={icons}
							/>
						</Table>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							defaultValue={count}
							component="div"
							count={sortedAndFilteredData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={(event, newPage) => setPage(newPage)}
							onRowsPerPageChange={(event) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
						/>
					</Box>
				</TableContainer>
			)}
		</>
	);
};

export default CustomTable;
