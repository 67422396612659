import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
	Chip,
	Alert,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import { Close } from "@mui/icons-material";
import { createOrUpdateLoanContract } from "../../services/retool-service";
import { useQueryClient } from "react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CustomSelect from "../Elements/CustomSelect";
import { useCurrentUserEmail } from "../../../common/helpers/hooks/useUserService";

interface CreateContractModalProps {
	open: boolean;
	onClose: () => void;
	contractData: any;
	type?: string;
	setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const CreateContractModal: React.FC<CreateContractModalProps> = ({
	open,
	onClose,
	contractData,
	type = "offer",
	setTabIndex,
}) => {
	const userEmail = useCurrentUserEmail();
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [lenderEmail, setLenderEmail] = useState(
		contractData?.lenderEmail || userEmail
	);
	const [lenderBankingAccount, setLenderBankingAccount] = useState(
		contractData?.lenderBankingAccount || ""
	);
	const [installmentAmount, setInstallmentAmount] = useState<number | string>(
		contractData?.installmentAmount || 0
	);
	const [installmentDates, setInstallmentDates] = useState<moment.Moment[]>(
		contractData?.installmentDates
			? contractData.installmentDates
					.split(",")
					.map((date: string) => moment(date, "DD/MM/YYYY"))
			: []
	);
	const [commencementDate, setCommencementDate] =
		useState<moment.Moment | null>(
			contractData?.commencementDate
				? moment(contractData.commencementDate, "DD/MM/YYYY")
				: null
		);
	const [province, setProvince] = useState(contractData?.province || "");
	const [penaltyAmount, setPenaltyAmount] = useState(
		contractData?.penaltyAmount || 0
	);
	const [useOfFunds, setUseOfFunds] = useState(contractData?.useOfFunds || "");
	const [finalRepaymentDate, setFinalRepaymentDate] =
		useState<moment.Moment | null>(
			contractData?.finalRepaymentDate
				? moment(contractData.finalRepaymentDate, "DD/MM/YYYY")
				: null
		);
	const queryClient = useQueryClient();

	const handleInstallmentDateChange = (newValue: moment.Moment | null) => {
		if (
			newValue &&
			!installmentDates.some((date) => date.isSame(newValue, "day"))
		) {
			setInstallmentDates((prev) => [...prev, newValue]);
		}
	};

	const handleDeleteInstallmentDate = (dateToDelete: moment.Moment) => {
		setInstallmentDates((prev) =>
			prev.filter((date) => !date.isSame(dateToDelete, "day"))
		);
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Saving contract...");

		const payload = {
			id: type == "offer" ? 0 : contractData?.id,
			lendingApplicationId: contractData?.lendingApplicationId,
			loanOfferId:
				type == "offer" ? contractData?.id : contractData?.loanOfferId,
			lenderEmail,
			lenderBankingAccount,
			installmentAmount,
			installmentDates: installmentDates
				.map((date) => date.format("DD/MM/YYYY"))
				.join(", "),
			commencementDate: commencementDate
				? commencementDate.format("DD/MM/YYYY")
				: "",
			province,
			penaltyAmount,
			finalRepaymentDate: finalRepaymentDate
				? finalRepaymentDate.format("DD/MM/YYYY")
				: "",
			useOfFunds,
		};

		try {
			const response = await createOrUpdateLoanContract(payload);
			if (response.data.success) {
				toast.success(response.data.message, { id: toastId });
				handleSetTabIndex();
			} else {
				toast.error(response.data.message, { id: toastId });
			}
			onClose();
			queryClient.invalidateQueries("loanContracts");
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleSetTabIndex = () => {
		if (setTabIndex) setTabIndex(2);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Loan Contract
				</Typography>

				<Alert severity="warning" icon={false}>
					<Typography variant="h6">Important</Typography>
					<Typography variant="body2" sx={{ mt: 1 }}>
						The fields will populate the contract template. You can specify
						their values now or later in the contract document
					</Typography>
				</Alert>

				<CustomTextInput
					name="lenderEmail"
					label="Lender Email"
					value={lenderEmail}
					onChange={(e) => setLenderEmail(e.target.value)}
					sx={{ mb: 2 }}
				/>

				<CustomTextInput
					name="installmentAmount"
					label="Installment Amount"
					value={installmentAmount}
					onChange={(e) => setInstallmentAmount(parseFloat(e.target.value))}
					sx={{ mb: 2 }}
					type="number"
				/>

				<Box width="100%" mt={1}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="Installment Dates (Select multiple)"
							value={null}
							onChange={handleInstallmentDateChange}
							minDate={moment()}
							sx={{ mb: 2, width: "100%" }}
						/>
					</LocalizationProvider>
				</Box>

				<Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
					{installmentDates.map((date, index) => (
						<Chip
							key={index}
							label={date.format("DD/MM/YYYY")}
							onDelete={() => handleDeleteInstallmentDate(date)}
						/>
					))}
				</Box>

				<Box width="100%" mt={1}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="Commencement Date"
							value={commencementDate}
							onChange={(newValue) => setCommencementDate(newValue)}
							minDate={moment()}
							sx={{ mb: 2, width: "100%" }}
						/>
					</LocalizationProvider>
				</Box>

				<Box width="100%" mt={1}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="Final Repayment Date"
							value={finalRepaymentDate}
							onChange={(newValue) => setFinalRepaymentDate(newValue)}
							minDate={moment()}
							sx={{ mb: 2, width: "100%" }}
						/>
					</LocalizationProvider>
				</Box>

				<CustomSelect
					name="province"
					label="Select Location"
					value={province}
					onChange={(e) => setProvince(e.target.value as string)}
					options={[
						{ value: "Eastern Cape", label: "Eastern Cape" },
						{ value: "Free State", label: "Free State" },
						{ value: "Gauteng", label: "Gauteng" },
						{ value: "KwaZulu-Natal", label: "KwaZulu-Natal" },
						{ value: "Limpopo", label: "Limpopo" },
						{ value: "Mpumalanga", label: "Mpumalanga" },
						{ value: "Northern Cape", label: "Northern Cape" },
						{ value: "North West", label: "North West" },
						{ value: "Western Cape", label: "Western Cape" },
					]}
				/>

				<CustomTextInput
					name="penaltyAmount"
					label="Penalty Amount"
					value={penaltyAmount}
					onChange={(e) => setPenaltyAmount(e.target.value)}
					sx={{ mb: 2 }}
					type="number"
				/>

				<CustomTextInput
					name="useOfFunds"
					label="Use of Funds"
					value={useOfFunds}
					onChange={(e) => setUseOfFunds(e.target.value)}
					sx={{ mb: 2 }}
					multiline
					rows={4}
				/>

				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					gap={2}
					mt={4}
				>
					<CustomButton
						text="Cancel"
						onClick={onClose}
						variant="outlined"
						color="secondary"
					/>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Save Contract"
							onClick={handleSubmit}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default CreateContractModal;
