import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import GreetingMessage from "../../common/components/Headings/GreetingMessage";
import BaseCard from "../../common/components/Cards/BaseCard";
import { AddCircle, Delete, Edit, Visibility } from "@mui/icons-material";
import CustomTable from "../../common/components/Tables/CustomTable";
import { CircularProgress, Tooltip, useTheme } from "@mui/material";
import CardWithIcon from "../../common/components/Elements/CardWithIcon";
import CustomSearchBar from "../../common/components/Elements/CustomSearchBar";
import CustomTabs from "../../common/components/Elements/CustomTabs";
import ConfirmationModal from "../../common/components/Modals/ConfirmationModal";
import { deleteLendingReminder } from "../../common/services/retool-service";
import toast from "react-hot-toast";
import { useFetchLendingReminders } from "../../common/helpers/hooks/useCollectionService";
import ReminderModal from "../../common/components/Modals/ReminderModal";
import { useFetchDeals } from "../../common/helpers/hooks/useClientService";
import LoadingIconButton from "../../common/components/Elements/LoadingIconButton";
import { useLocation } from "react-router-dom";

const ManageCollectionsPage: React.FC = () => {
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [openCreateOrUpdateReminderModal, setOpenCreateOrUpdateReminderModal] =
		useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);

	const {
		data: reminders,
		refetch: refetchReminders,
		isLoading: isLoadingReminders,
		isRefetching: isRefetchingReminders,
	} = useFetchLendingReminders();

	const {
		data: deals,
		refetch: refetchDeals,
		isLoading: isFetchingDeals,
		isRefetching,
	} = useFetchDeals();

	const refetch = () => {
		refetchReminders();
		refetchDeals();
	};

	const [selectedReminder, setSelectedReminder] = useState<any>(null);
	const [filteredRemindersData, setFilteredRemindersData] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const theme = useTheme();

	const tabs = [{ label: "Reminders" }, { label: "Mandates" }];

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleDelete = (reminder: any) => {
		setSelectedReminder(reminder);
		setOpenConfirmationModal(true);
		setConfirmationMessage(`Are you sure you want to delete this reminder?`);
	};

	const handleDeleteReminder = async () => {
		if (selectedReminder) {
			try {
				setIsLoading(true);
				const response = await deleteLendingReminder(selectedReminder.id);
				if (response.data.success) {
					toast.success(response.data.message);
					refetch();
				} else {
					toast.error(response.data.message);
				}
				setIsLoading(false);
				setOpenConfirmationModal(false);
			} catch (error) {
				toast.error("Unable to delete reminder.");
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (reminders?.data?.data) {
			const searchTermLower = searchTerm.toLowerCase();
			const newFilteredData = reminders?.data?.data.filter(
				(reminder: any) =>
					reminder?.name?.toLowerCase().includes(searchTermLower) ||
					reminder?.description?.toLowerCase().includes(searchTermLower) ||
					reminder?.email?.toLowerCase().includes(searchTermLower)
			);
			setFilteredRemindersData(newFilteredData);
		}
	}, [searchTerm, reminders]);

	const location = useLocation();

	useEffect(() => {
		refetchReminders();
		refetchDeals();
	}, [location.pathname]);

	return (
		<Box
			sx={{
				py: 4,
				px: 3,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				textAlign: "center",
				width: "100%",
				gap: 2,
			}}
		>
			<BaseCard sx={{ height: "auto" }}>
				<Box px={2} py={2}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<GreetingMessage title="Manage Reminders" />
						<LoadingIconButton
							onClick={refetch}
							isLoading={isRefetchingReminders || isRefetching}
						/>
					</Box>

					<Box
						width="100%"
						my={2}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<CustomTabs
							tabs={tabs}
							selectedTab={tabIndex}
							onTabChange={(event, newValue) => setTabIndex(newValue)}
						/>
					</Box>
					{tabIndex == 0 && (
						<Box
							my={2}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<CustomSearchBar
								searchTerm={searchTerm}
								onSearchChange={handleSearchChange}
								placeholder="Search Reminders"
							/>
							<CardWithIcon
								icon={
									<AddCircle
										style={{
											color:
												theme.palette.mode == "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "34px",
										}}
									/>
								}
								text="Add Reminder"
								onClick={() => {
									setSelectedReminder(null);
									setOpenCreateOrUpdateReminderModal(true);
								}}
							/>
						</Box>
					)}

					{tabIndex === 0 && (
						<CustomTable
							isLoading={isLoadingReminders}
							icons={(reminder) => [
								{
									icon: (
										<Tooltip title="Edit Reminder" arrow>
											<Edit
												sx={{
													fill: theme.palette.primary.main,
													fontSize: "20px",
												}}
											/>
										</Tooltip>
									),
									onClick: () => {
										setSelectedReminder(reminder);
										setOpenCreateOrUpdateReminderModal(true);
									},
								},
								{
									icon: (
										<Tooltip title="Delete Reminder" arrow>
											<Delete sx={{ fill: "red", fontSize: "20px" }} />
										</Tooltip>
									),
									onClick: () => handleDelete(reminder),
								},
							]}
							columns={[
								{ title: "Email", key: "email", sortable: true },
								{ title: "Name", key: "name", sortable: true },
								{ title: "Description", key: "description", sortable: true },
								{
									title: "Status",
									key: "status",
									sortable: true,
									isPill: true,
								},
								{
									title: "Frequency",
									key: "frequency",
									sortable: true,
									isPill: true,
								},
								{ title: "Updated At", key: "updatedAt", sortable: true },
								{ title: "End Date", key: "endDate", sortable: true },
								{ title: "Time", key: "customTime", sortable: true },
							]}
							data={filteredRemindersData}
							actionColumn={{ title: "Actions" }}
							onRowSelect={() => {}}
							selectable
						/>
					)}
				</Box>
			</BaseCard>

			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteReminder}
				message={confirmationMessage}
				isLoading={isLoading}
			/>

			<ReminderModal
				open={openCreateOrUpdateReminderModal}
				onClose={() => setOpenCreateOrUpdateReminderModal(false)}
				reminderData={selectedReminder}
				deals={deals?.data?.data || []}
			/>
		</Box>
	);
};

export default ManageCollectionsPage;
