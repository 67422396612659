import authService from "../../../common/services/auth-service";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";

const useForgotPasswordForm = (email: string) => {
  return useFormik({
    initialValues: {
      email: email || "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await authService.forgotPassword(values.email);
        helpers.setStatus({ success: true });
        toast.success(" Password reset request submitted. An email will be sent to you.");
      } catch (err) {
        console.error(err);

        
        if (err instanceof Error) {
          
          const errorMessage = (err as any)?.response?.data?.error || "Failed to submit";
          helpers.setErrors({ submit: errorMessage });
        } else {
          helpers.setErrors({ submit: "An unknown error occurred" });
        }

        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });
};

export default useForgotPasswordForm;
