import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";


const useUserId = () => {
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  return userId || null;
};


const useCurrentUser = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  return user || null;
};


const useCurrentUserEmail = () => {
  const email = useSelector((state: RootState) => state.auth.user?.email);
  return email || "";
};

export {
  useCurrentUser,
  useCurrentUserEmail,
  useUserId,
};
