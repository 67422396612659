import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from '../common/features/auth/authSlice';
import { manageLabellingReducer } from '../common/features/labelling/manageLabellingSlice';
import componentReducer from '../common/features/components/componentSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'token', 'isAuthenticated'],
};

const manageLabellingPersistConfig = {
  key: 'manageLabelling',
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedManageLabellingReducer = persistReducer(manageLabellingPersistConfig, manageLabellingReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    manageLabelling: persistedManageLabellingReducer,
    components: componentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
