import React, { useState } from "react";
import {
	Modal,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	List,
	ListItem,
	ListItemText,
	IconButton,
	Tooltip,
	CircularProgress,
	useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useQuery } from "react-query";
import CustomTabs from "../Elements/CustomTabs";
import { Close } from "@mui/icons-material";
import LoadingIconButton from "../Elements/LoadingIconButton";
import { toast } from "react-hot-toast";
import {
	getConsumerCreditReport,
	getCommercialCreditReport,
} from "../../../common/services/retool-service";

const AccordionModal = ({ open, handleClose, selectedRow }) => {
	const userId = selectedRow?.userId;
	const [activeTab, setActiveTab] = useState(0);
	const tabs = [{ label: "Consumer" }, { label: "Commercial" }];
	const theme = useTheme();

	const formatKey = (key) =>
		key
			.replace(/([a-z])([A-Z])/g, "$1 $2")
			.replace(/^./, (str) => str.toUpperCase());

	const {
		data: consumerData,
		isLoading: consumerLoading,
		isRefetching: consumerRefetching,
		refetch: refetchConsumerReport,
	} = useQuery(
		["consumerCreditReports", userId],
		() => (userId ? getConsumerCreditReport(userId) : Promise.resolve(null)),
		{ staleTime: Infinity, enabled: !!userId }
	);

	const {
		data: commercialData,
		isLoading: commercialLoading,
		isRefetching: commercialRefetching,
		refetch: refetchCommercialReport,
	} = useQuery(
		["commercialCreditReports", userId],
		() =>
			userId
				? getCommercialCreditReport(selectedRow?.userId)
				: Promise.resolve(null),
		{ staleTime: Infinity, enabled: !!userId }
	);

	const handleCopy = (value) => {
		navigator.clipboard.writeText(value);
		toast.success("Copied to clipboard!");
	};

	const renderContent = (key, value) => {
		if (Array.isArray(value)) {
			return (
				<List>
					{value.map((item, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								justifyContent: "space-between",
								padding: "12px 0",
								borderBottom: "1px solid #ccc",
							}}
						>
							<Typography>
								<strong>Account Type Code: </strong>{" "}
								{item.accountTypeCode || "N/A"}
							</Typography>
							<Typography>
								<strong>Description: </strong> {item.accountTypeDesc || "N/A"}
							</Typography>
							<IconButton
								onClick={() =>
									handleCopy(
										`${item.accountTypeCode} ${item.accountTypeDesc}` || ""
									)
								}
							>
								<ContentCopyIcon color="success" />
							</IconButton>
						</Box>
					))}
				</List>
			);
		} else if (typeof value === "object" && value !== null) {
			return (
				<Box>
					{Object.entries(value)
						.filter(
							([subKey]) =>
								subKey.toLowerCase() !== "id" ||
								subKey.toLowerCase() !== "userid"
						)
						.map(([subKey, subValue]) => (
							<Box
								key={subKey}
								sx={{
									display: "flex",
									justifyContent: "space-between",
									padding: "12px 0",
									borderBottom: "1px solid #eee",
								}}
							>
								<Typography sx={{ flex: 1 }}>
									<strong>{formatKey(subKey)}: </strong>
									<span>{subValue || "N/A"}</span>
								</Typography>
								<IconButton
									onClick={() => handleCopy(subValue || "")}
									sx={{
										padding: "4px",
										color: theme.palette.primary.main,
									}}
								>
									<ContentCopyIcon color="success" />
								</IconButton>
							</Box>
						))}
				</Box>
			);
		}
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "12px 0",
				}}
			>
				<Typography sx={{ flex: 1 }}>
					<strong>{formatKey(key)}: </strong> {value || "N/A"}
				</Typography>
				<IconButton
					onClick={() => handleCopy(value || "")}
					sx={{
						padding: "4px",
						color: theme.palette.primary.main,
					}}
				>
					<ContentCopyIcon color="success" />
				</IconButton>
			</Box>
		);
	};

	const sortedConsumerData = consumerData?.data?.data
		? Object.keys(consumerData.data.data)
				.sort((key) => (key === "consumerScoring" ? -1 : 0))
				.reduce((acc, key) => {
					acc[key] = consumerData.data.data[key];
					return acc;
				}, {})
		: {};

	const sortedCommercialData = commercialData?.data?.data
		? Object.keys(commercialData.data.data)
				.sort((key) => (key === "commercialScoring" ? -1 : 0))
				.reduce((acc, key) => {
					acc[key] = commercialData.data.data[key];
					return acc;
				}, {})
		: {};

	const selectedData =
		selectedRow?.type === "Consumer"
			? sortedConsumerData
			: sortedCommercialData;

	const isLoading =
		selectedRow?.type == "Consumer" ? consumerLoading : commercialLoading;
	const isRefetching =
		selectedRow?.type == "Consumer" ? consumerRefetching : commercialRefetching;

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={handleClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					{selectedRow?.type} Credit Report: {selectedRow?.clientName}
				</Typography>

				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					my={2}
				>
					<LoadingIconButton
						onClick={() => {
							refetchConsumerReport();
							refetchCommercialReport();
						}}
						isLoading={consumerRefetching || commercialRefetching}
					/>
				</Box>

				{isLoading || isRefetching ? (
					<CircularProgress size={24} color="success" />
				) : selectedData ? (
					Object.entries(selectedData)
						.filter(([key]) => !["userId", "id"].includes(key))
						.map(([key, value]) => (
							<Accordion key={key}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography textTransform="capitalize">
										{formatKey(key)}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>{renderContent(key, value)}</AccordionDetails>
							</Accordion>
						))
				) : (
					<Typography sx={{ mt: 2 }}>No data available.</Typography>
				)}
			</Box>
		</Modal>
	);
};

export default AccordionModal;
