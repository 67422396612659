import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from "react";
import useComponentRegistry from "../configs/componentRegistry.js";

interface ComponentItem {
	id: string;
	component: React.ReactNode;
	isFullWidth?: boolean;
}

interface ComponentRegistryContextType {
	components: ComponentItem[];
	pinnedComponents: ComponentItem[];
}

const ComponentRegistryContext =
	createContext<ComponentRegistryContextType | null>(null);

export const ComponentRegistryProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { getAllComponents, getAllPinnedComponents } = useComponentRegistry();

	const components = getAllComponents();
	const pinnedComponents = getAllPinnedComponents();

	return (
		<ComponentRegistryContext.Provider value={{ components, pinnedComponents }}>
			{children}
		</ComponentRegistryContext.Provider>
	);
};

export const useComponentRegistryContext = () => {
	const context = useContext(ComponentRegistryContext);
	if (!context) {
		throw new Error(
			"useComponentRegistryContext must be used within a ComponentRegistryProvider"
		);
	}
	return context;
};
