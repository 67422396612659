import React, { FC } from "react";
import {
	Box,
	Button,
	CircularProgress,
	FormHelperText,
	TextField,
	Typography,
} from "@mui/material";
import useForgotPasswordForm from "../../../common/helpers/hooks/useForgotPasswordForm";

interface ForgotPasswordFormProps {
	email?: string;
	isLoading: boolean;
	showPasswordResetDialog: boolean;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
	email = "",
	isLoading,
	showPasswordResetDialog,
}) => {
	const formik = useForgotPasswordForm(email);

	return (
		<form className="auth-form" noValidate onSubmit={formik.handleSubmit}>
			{!showPasswordResetDialog ? (
				<TextField
					autoFocus
					error={Boolean(formik.touched.email && formik.errors.email)}
					fullWidth
					helperText={formik.touched.email && formik.errors.email}
					label="Email Address"
					margin="normal"
					name="email"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					type="email"
					size="small"
					value={formik.values.email}
				/>
			) : (
				<Typography variant="body1">
					Password reset request submitted. An email will be sent to you.
				</Typography>
			)}

			{formik.errors.submit && (
				<Box sx={{ mt: 3 }}>
					<FormHelperText error>{formik.errors.submit}</FormHelperText>
				</Box>
			)}

			<Box sx={{ mt: 2 }}>
				{isLoading ? (
					<Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
						<CircularProgress />
					</Box>
				) : (
					!showPasswordResetDialog && (
						<Button
							disabled={formik.isSubmitting}
							fullWidth
							type="submit"
							sx={{ background: "#619C9C" }}
							size="small"
							variant="contained"
						>
							Submit
						</Button>
					)
				)}
			</Box>
		</form>
	);
};

export default ForgotPasswordForm;
