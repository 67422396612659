import React, { useState } from "react";
import {
	Modal,
	Box,
	Typography,
	CircularProgress,
	useTheme,
} from "@mui/material";
import CustomButton from "../Elements/CustomButton";
import CustomSelect from "../Elements/CustomSelect";
import toast from "react-hot-toast";
import { saveApplicationDataRequest } from "../../../common/services/retool-service";
import { useQueryClient } from "react-query";

const ClientRequestModal = ({ open, handleClose, deal, doc }) => {
	const theme = useTheme();
	const [assignee, setAssignee] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const handleProductType = () => {
		let productType = deal?.loanType;

		switch (productType) {
			case "STIF":
				return "short-term-input-finance";
			case "InvoiceFinance":
				return "invoice-finance";
			case "PurchaseOrderFinance":
				return "purchase-order-loan";
			case "AssetFinance":
				return "asset-finance";
			default:
				return "";
		}
	};

	const handleSubmit = async () => {
		if (!assignee) {
			toast.error("Please select an assignee.");
			return;
		}

		const payload = {
			lendingApplicationId: deal?.id,
			email: deal?.email,
			assignee,
			productType: handleProductType(),
			documentRef: doc?.documentRef,
		};

		setIsLoading(true);
		try {
			const response = await saveApplicationDataRequest(payload);
			if (response?.data?.success) toast.success(response?.data?.message);
			else toast.error(response?.data?.message);
			setIsLoading(false);
			queryClient.invalidateQueries("deals");
			handleClose();
		} catch (error) {
			toast.error("Failed to save request.");
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: "relative",
					top: "30%",
					padding: 4,
					backgroundColor: theme.palette.background.paper,
					borderRadius: 2,
					outline: "none",
					width: "600px",
					margin: "auto",
				}}
			>
				<Typography variant="h6" mb={3}>
					Client Request
				</Typography>
				<Typography>
					Would you like to add {deal?.requirementDescription} to the list of
					client request records? Note this will not be sent to the user right
					now. You will have the option of sending all the requests later.
				</Typography>
				<CustomSelect
					name="assignee"
					label="Assignee"
					options={[
						{
							value: "Jerome - jerome@pumpkn.io",
							label: "Jerome - jerome@pumpkn.io",
						},
						{
							value: "Fazlur - fazlur@pumpkn.io",
							label: "Fazlur - fazlur@pumpkn.io",
						},
					]}
					value={assignee}
					onChange={(e) => setAssignee(e.target.value)}
				/>
				<Box
					display="flex"
					justifyContent="flex-end"
					alignItems="center"
					mt={2}
					gap={2}
				>
					<CustomButton
						text="Cancel"
						onClick={handleClose}
						variant="outlined"
						color="secondary"
					/>
					{isLoading ? (
						<CircularProgress size="1rem" color="success" />
					) : (
						<CustomButton
							text="Submit"
							onClick={handleSubmit}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default ClientRequestModal;
