import React from 'react';
import InfoCard from '../common/components/Cards/InfoCard';
import ListCard from '../common/components/Cards/ListCard';
import SummaryCard from '../common/components/Cards/SummaryCard';
import ChartSummaryCard from '../common/components/Cards/ChartSummaryCard';
import LineChartSummaryCard from '../common/components/Cards/LineChartSummaryCard';
import TaskCard from '../common/components/Cards/TaskCard';
import TableCard from '../common/components/Widgets/TableCard';
import FinanceCard from '../common/components/Cards/FinanceCard';
import RatingCard from '../common/components/Cards/RatingCard';
import ProfileCard from '../common/components/Cards/ProfileCard';
import DoughnutSummaryCard from '../common/components/Cards/DoughnutSummaryCard';
import BarChartSummaryCard from '../common/components/Cards/BarChartSummaryCard';
import ColoredBoxSummaryCard from '../common/components/Cards/ColoredBoxSummaryCard';
import ColoredBoxSummaryCard2 from '../common/components/Cards/ColoredBoxSummaryCard2';
import ClientList from '../common/components/Cards/ClientList';
import NoDataFoundCard from '../common/components/Cards/NoDataFoundCard';
import {
    chartSummaryCardLoanBreakdownProps,
    chartSummaryCardLoanPerformanceProps,
    chartSummaryCardLoanRatiosProps,
    chartSummaryCardPortfolioDiversityProps,
    clientListProps,
    debtServiceCoverRatioRatingCardProps,
    flaggedTransactionsTableProps,
    lineChartSummaryCardCollectiveFinancialRecordsProps,
    lineChartSummaryCardRepaymentHistoryProps,
    listCardPropsActivityLog,

    portfolioBusinessSizeDoughnutSummaryCardProps,
    profileRiskStatusProps,
    riskHistoryProps,

} from '../data/dataSource';

import { useClientsSummaryCard, useHistorySummaryCard, useIncomeAndFinanceCard, useInfoRiskCard, usePinnedClientTable, useProvincialPresence, useTask, useTaskList, useTopIndustries } from '../common/helpers/hooks/widgets';
import useComponentService from '../common/helpers/hooks/useComponentService';

const useComponentRegistry = () => {

    const {
		
		componentIds
	} = useComponentService();


    const { data: provincialPresenceProps } = useProvincialPresence();
    const { data: pinnedClientTableProps } = usePinnedClientTable();
    const { data: summaryCardClientsProps } = useClientsSummaryCard();
    const { data: summaryCardHistoryProps } = useHistorySummaryCard();
    const { data: topIndustriesColoredBoxSummaryCardProps } = useTopIndustries();
    const { data: incomeAndfinanceCardProps } = useIncomeAndFinanceCard();
    const { data: listCardPropsTaskList } = useTaskList();
    const { data: taskCardProps } = useTask();
    const { data: infoRiskCardProps } = useInfoRiskCard();

    const components = [
        {
            id: "Risks",
            component: <InfoCard {...infoRiskCardProps} />,
            isFullWidth: false
        },
        {
            id: "ActivityLog",
            component: <ListCard {...listCardPropsActivityLog} />,
            isFullWidth: false
        },
        {
            id: "Clients",
            component: <SummaryCard {...summaryCardClientsProps} />,
            isFullWidth: false
        },
        {
            id: "History",
            component: <SummaryCard {...summaryCardHistoryProps} />,
            isFullWidth: false
        },
        {
            id: "LoanPerformance",
            component: <ChartSummaryCard {...chartSummaryCardLoanPerformanceProps} />,
            isFullWidth: false
        },
        {
            id: "PortfolioDiversity",
            component: <ChartSummaryCard {...chartSummaryCardPortfolioDiversityProps} />,
            isFullWidth: false
        },
        {
            id: "LoanBreakdown",
            component: <ChartSummaryCard {...chartSummaryCardLoanBreakdownProps} />,
            isFullWidth: false
        },
        {
            id: "LoanRatios",
            component: <ChartSummaryCard {...chartSummaryCardLoanRatiosProps} />,
            isFullWidth: false
        },
        {
            id: "Tasks",
            component: <TaskCard {...taskCardProps} />,
            isFullWidth: false
        },
        {
            id: "TaskList",
            component: <ListCard {...listCardPropsTaskList} />,
            isFullWidth: false
        },
        {
            id: "RepaymentHistory",
            component: <LineChartSummaryCard {...lineChartSummaryCardRepaymentHistoryProps} />,
            isFullWidth: false
        },
        {
            id: "CollectiveFinancialRecords",
            component: <LineChartSummaryCard {...lineChartSummaryCardCollectiveFinancialRecordsProps} />,
            isFullWidth: false
        },
        {
            id: "IncomeAndExpenses",
            component: <FinanceCard {...incomeAndfinanceCardProps} />,
            isFullWidth: false
        },
        {
            id: "DebtServiceCoverRatio",
            component: <RatingCard {...debtServiceCoverRatioRatingCardProps} />,
            isFullWidth: false
        },
        {
            id: "ProfileRiskStatus",
            component: <ProfileCard {...profileRiskStatusProps} />,
            isFullWidth: true
        },
        {
            id: "PortfolioBusinessSizes",
            component: <DoughnutSummaryCard {...portfolioBusinessSizeDoughnutSummaryCardProps} />,
            isFullWidth: false
        },
        {
            id: "Top3Industries",
            component: <ColoredBoxSummaryCard {...topIndustriesColoredBoxSummaryCardProps} />,
            isFullWidth: false
        },
        {
            id: "ProvincialPresence",
            component: <ColoredBoxSummaryCard2 {...provincialPresenceProps} />,
            isFullWidth: false,
        },
        {
            id: "NewClients",
            component: <ClientList {...clientListProps} />,
            isFullWidth: false
        },
        {
            id: "RiskHistory",
            component: <BarChartSummaryCard {...riskHistoryProps} />,
            isFullWidth: false
        },
        {
            id: "NoDataFoundCard",
            component: <NoDataFoundCard title="No Data" />,
        },
        {
            id: "PinnedClients",
            component: <TableCard {...pinnedClientTableProps} />,
            isFullWidth: true
        },
        {
            id: "FlaggedTransactions",
            component: <TableCard {...flaggedTransactionsTableProps} />,
            isFullWidth: true
        },
    ];

    return {
        components,
        getComponentById: (id) => components.find(component => component.id === id),
        getAllComponents: () => components,
        getAllPinnedComponents: () => {
            const pinnedComponentIds = componentIds;
            return components.filter(c => pinnedComponentIds.includes(c.id));
        },
    };
};

export default useComponentRegistry; 
