import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../app/store";
import { addComponentId, clearComponentIds, removeComponentId } from "../../features/components/componentSlice";

// Custom hook
const useComponentService = () => {
  const dispatch = useDispatch();

  // Get component IDs from Redux store
  const componentIds = useSelector((state: RootState) => state.components.componentIds) || [];

  // Function to save component ID
  const saveComponentId = (componentId: string) => {
    dispatch(addComponentId(componentId));  
  };

  // Function to remove component ID by its identifier
  const removeComponentIdById = (componentId: string) => {
    dispatch(removeComponentId(componentId)); 
  };

  // Function to clear all component IDs
  const clearAllComponentIds = () => {
    dispatch(clearComponentIds());  
  };

  // Return functions and state
  return {
    componentIds,
    saveComponentId,
    removeComponentIdById,
    clearAllComponentIds,
  };
};

export default useComponentService;
