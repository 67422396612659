import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import { Close } from "@mui/icons-material";
import { updateDealStatus } from "../../services/retool-service"; // adjust import as needed
import { useQueryClient } from "react-query";

interface UpdateDealStatusModalProps {
	open: boolean;
	onClose: () => void;
	deal?: any;
}

const UpdateDealStatusModal: React.FC<UpdateDealStatusModalProps> = ({
	open,
	onClose,
	deal,
}) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState(deal?.status?.toLowerCase() || "");
	const queryClient = useQueryClient();

	const handleUpdateStatus = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Updating deal status...");

		try {
			if (
				status === "approved" &&
				deal?.loanType?.toLowerCase() === "unspecified"
			) {
				toast.error(
					"You cannot approve an unspecified deal. Please specify the product type for this deal.",
					{ id: toastId }
				);
				return;
			}

			const payload = {
				lendingApplicationId: deal?.id,
				status,
			};

			const response = await updateDealStatus(payload);
			const message =
				response?.data?.message || "Deal status updated successfully!";

			toast.success(message, { id: toastId });

			queryClient.invalidateQueries([
				"deals",
				"clientAndDealSummary",
				"approvedOffers",
			]);

			onClose();
		} catch (error: any) {
			const errorMessage =
				error?.response?.data?.message || "An error occurred.";
			toast.error(errorMessage, { id: toastId });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h6"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Update Deal Status: {deal?.email}
				</Typography>

				<FormControl component="fieldset" sx={{ mb: 2 }}>
					<RadioGroup
						aria-label="deal-status"
						name="deal-status"
						value={status}
						onChange={(e) => setStatus(e.target.value)}
					>
						<FormControlLabel
							value="approved"
							control={<Radio />}
							label="Approve"
						/>
						<FormControlLabel
							value="declined"
							control={<Radio />}
							label="Reject"
						/>
						<FormControlLabel
							value="reviewed"
							control={<Radio />}
							label="Review"
						/>
						<FormControlLabel
							value="paidUp"
							control={<Radio />}
							label="Paid Up"
						/>
					</RadioGroup>
				</FormControl>

				<Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Update Status"
							onClick={handleUpdateStatus}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateDealStatusModal;
