import React, { useState } from "react";
import {
	CreditScore,
	Add,
	Person,
	AccountBalance,
	Description,
	BarChart,
	Delete,
	Edit,
	Money,
	HandshakeOutlined,
	Send,
} from "@mui/icons-material";

import FloatingActionMenu, { Action } from "../Elements/Fab";
import LinkBankAccountModal from "../Modals/LinkBankAccountModal";
import FinancialDataModal from "../Modals/FinancialDataModal";

import moment from "moment";
import useUserBankData from "../../helpers/hooks/useUserBankData";
import {
	useClientDealChecklists,
	useDealChecklists,
} from "../../helpers/hooks/useClientService";
import ClientDealChecklistModal from "../Modals/ClientDealChecklistModal";
import CreditReportModal from "../Modals/CreditReportModal";
import UpdateProfileModal from "../Modals/UpdateProfileModal";
import FundingNeedModal from "../Modals/FundingNeedModal";
import ConfirmationModal from "../Modals/ConfirmationModal";
import toast from "react-hot-toast";
import {
	deleteOffers,
	sendLoanOffer,
	softDeleteDeals,
} from "../../services/retool-service";
import DealChecklistModal from "../Modals/DealChecklistModal";
import UpdateDealStatusModal from "../Modals/UpdateDealStatus";
import LoanOfferModal from "../Modals/LoanOfferModal";
import CreateContractModal from "../Modals/CreateContractModal";
import UpdateOfferStatusModal from "../Modals/UpdateOfferStatus";
import { useQueryClient } from "react-query";

interface FabProps {
	data?: any;
	selectedRows: any[];
	setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const OffersFloatingActionMenu: React.FC<FabProps> = ({
	data,
	selectedRows,
	setTabIndex,
}) => {
	const userId = data?.userId;
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const [openAddContractModal, setOpenAddContractModal] = useState(false);
	const [openCreateOfferModal, setOpenCreateOfferModal] = useState(false);
	const [openUpdateOfferStatusModal, setOpenUpdateOfferStatusModal] =
		useState(false);
	const [openSendOfferStatusModal, setOpenSendOfferStatusModal] =
		useState(false);

	const handleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedRows.map((row) => row.email).join(", ");

		setConfirmationMessage(
			`Are you sure you want to delete these offers: ${emails}?`
		);
	};

	const handleSendOfferConfirmation = () => {
		setOpenSendOfferStatusModal(true);

		setConfirmationMessage(
			`This action will send the loan offer to this email: ${data?.email}. Are you sure you want to continue?`
		);
	};

	const handleDeleteOffers = async () => {
		try {
			setIsLoading(true);
			const payload = {
				Ids: selectedRows.map((row) => row.id),
			};

			const response = await deleteOffers(payload);
			if (response.success) {
				toast.success(response.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
				queryClient.invalidateQueries("approvedOffers");
				queryClient.invalidateQueries("loanOffersSummary");
			} else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
		} catch (error) {
			toast.error("Unable to delete offers.");
			setIsLoading(false);
		}
	};

	const handleSendOffer = async () => {
		try {
			setIsLoading(true);

			const response = await sendLoanOffer(data.id);
			if (response?.data?.success) {
				toast.success(response?.data?.message);
				queryClient.invalidateQueries("loanOffers");
				queryClient.invalidateQueries("loanContracts");
				queryClient.invalidateQueries("approvedOffers");
				queryClient.invalidateQueries("loanOffersSummary");
			} else toast.error(response?.data?.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
			setOpenSendOfferStatusModal(false);
		} catch (error) {
			toast.error("Unable to send loan offer");

			setIsLoading(false);
		}
	};

	const actions: Action[] = [
		{
			label: "Delete Offers",
			icon: <Delete />,
			onClick: () => {
				handleDelete();
			},
		},

		{
			label: "Update Offer",
			icon: <Edit />,
			onClick: () => {
				setOpenUpdateOfferStatusModal(true);
			},
		},
		{
			label: "Send Offer",
			icon: <Send />,
			onClick: () => {
				handleSendOfferConfirmation();
			},
		},
		{
			label: "Create Contract",
			icon: <HandshakeOutlined />,
			onClick: () => {
				if (data.status != "Accepted")
					toast.error(
						"The contract for this offer cannot be created as the offer has not yet been accepted."
					);
				else setOpenAddContractModal(true);
			},
		},
	];

	return (
		<>
			<LoanOfferModal
				open={openCreateOfferModal}
				onClose={() => setOpenCreateOfferModal(false)}
				selectedApplication={null}
				client={data}
			/>

			<CreateContractModal
				open={openAddContractModal}
				onClose={() => setOpenAddContractModal(false)}
				contractData={data}
				setTabIndex={setTabIndex}
			/>
			<UpdateOfferStatusModal
				open={openUpdateOfferStatusModal}
				onClose={() => setOpenUpdateOfferStatusModal(false)}
				offer={data}
			/>

			<FloatingActionMenu actions={actions} />
			<ConfirmationModal
				open={openSendOfferStatusModal}
				onClose={() => setOpenSendOfferStatusModal(false)}
				handleConfirmAction={handleSendOffer}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteOffers}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
		</>
	);
};

export default OffersFloatingActionMenu;
