import { LoginPage } from "../pages/Authentication/Login";
import ClientsPage from "../pages/Clients/Index";
import SingleClientPage from "../pages/Clients/SingleClient";
import DashboardPage from "../pages/Dashboard/Index";
import FinancialDataPage from "../pages/FinancialData/Index";
import FlagDetails from "../pages/Flagging/FlagDetails";
import FlaggingPage from "../pages/Flagging/Index";
import InsightsPage from "../pages/Insights/Index";
import OnboardingPage from "../pages/Onboarding/Index";
import React from "react";
import { Routes, Route } from "react-router-dom";

import ManageClientsPage from "../pages/ManageClients/Index";
import ManageDealsPage from "../pages/ManageDeals/Index";
import ManageOffersPage from "../pages/Offers/Index";
import ManageCreditReports from "../pages/ManageCreditReports/Index";
import ManageCollectionsPage from "../pages/Collections/Index";

const AppRoutes = () => (
	<Routes>
		<Route path="/" element={<ManageClientsPage />} />
		{/* <Route path="/onboarding" element={<OnboardingPage />} /> */}
		<Route path="monitor/insights" element={<InsightsPage />} />
		<Route path="monitor/clients" element={<ClientsPage />} />
		{/* <Route path="monitor" element={<DashboardPage />} /> */}
		<Route path="clients/:id" element={<SingleClientPage />} />
		<Route path="monitor/dashboard" element={<DashboardPage />} />
		<Route path="monitor/flagging" element={<FlaggingPage />} />
		<Route path="monitor/flagging/manage" element={<FlagDetails />} />
		{/* <Route path="review" element={<ManageClientsPage />} /> */}
		<Route path="manage-clients" element={<ManageClientsPage />} />
		<Route path="manage-deals" element={<ManageDealsPage />} />
		<Route path="manage-offers" element={<ManageOffersPage />} />
		<Route path="manage-reports" element={<ManageCreditReports />} />
		<Route path="review/financial-data" element={<FinancialDataPage />} />
		<Route
			path="review/manage-collections"
			element={<ManageCollectionsPage />}
		/>
	</Routes>
);

export default AppRoutes;
