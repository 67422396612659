import React, { useState, useEffect } from "react";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import BaseCard from "../Cards/BaseCard";
import CustomCardHeader from "../Cards/CustomCardHeader";
import CustomSelect from "../Elements/CustomSelect";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import ResponsiveGrid from "../Grids/ResponsiveGrid";
import toast from "react-hot-toast";
import {
	duplicateFundingNeed,
	saveFundingNeed,
} from "../../services/retool-service";
import { BusinessData } from "../../../data/businessData";
import { QueryClient, useQueryClient } from "react-query";
import { getProductType } from "../../helpers/utils/productUtils";

interface FundingNeedModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
	client?: any;
}

const DuplicateFundingNeedModal: React.FC<FundingNeedModalProps> = ({
	open,
	onClose,
	selectedApplication,
	client,
}) => {
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({
		fundingAmount: selectedApplication?.fundingAmount || 5000,
		duration: selectedApplication?.duration || 5,
		useOfFunds: selectedApplication?.useOfFunds || [],
		securityOrCollateral: selectedApplication?.securityOrCollateral || "None",
		productType: selectedApplication?.productType || "",
		productTypeAdditionalContext:
			selectedApplication?.productTypeAdditionalContext || "",
		useOfFundsAdditionalContext:
			selectedApplication?.useOfFundsAdditionalContext || "",
		securityAdditionalContext:
			selectedApplication?.securityAdditionalContext || "",
		userId: client?.userId,
		applicationId: 0,
		email: client?.email,
	});

	useEffect(() => {
		if (selectedApplication) {
			setFormData((prevFormData) => ({
				...prevFormData,
				...selectedApplication,
				useOfFunds: selectedApplication?.useOfFunds?.split(",") || [],
				productType: getProductType(selectedApplication?.loanType || ""),
			}));
		}
	}, [selectedApplication]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSelectChange = (name: string) => (event: any) => {
		const value = event.target.value;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Duplicating funding need...");

		const payload = {
			userId: client?.userId,
			fundingAmount: formData.fundingAmount,
			duration: formData.duration,
			useOfFunds: formData.useOfFunds.join(","),
			securityOrCollateral: formData.securityOrCollateral,
			productType: formData.productType,
			productTypeAdditionalContext: formData.productTypeAdditionalContext,
			useOfFundsAdditionalContext: formData.useOfFundsAdditionalContext,
			securityAdditionalContext: formData.securityAdditionalContext,
			applicationId: 0,
			companyRegistrationNo: selectedApplication?.companyRegistrationNo,
			idNumber: selectedApplication?.idNumber,
			email: formData?.email,
		};

		try {
			const response = await duplicateFundingNeed(payload);
			toast.success(response.data.message, { id: toastId });
			queryClient.invalidateQueries("deals");
			queryClient.invalidateQueries("clientAndDealSummary");
			onClose();
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Duplicate Deal</DialogTitle>
			<DialogContent>
				<Box>
					<CustomTextInput
						name="email"
						label="Email (Provide email address for new deal)"
						type="email"
						value={formData.email}
						onChange={handleInputChange}
						sx={{ my: 2 }}
					/>
					<ResponsiveGrid columnCount={2}>
						<BaseCard sx={{ padding: "20px", height: "auto" }}>
							<Box component="form">
								<CustomCardHeader title="Funding Details" />

								<CustomTextInput
									name="fundingAmount"
									label="Estimated Funding Amount (Rands)"
									type="number"
									value={formData.fundingAmount}
									onChange={handleInputChange}
									sx={{ mb: 2 }}
								/>

								<CustomTextInput
									name="duration"
									label="Estimated Duration (Days)"
									type="number"
									value={formData.duration}
									onChange={handleInputChange}
									sx={{ mb: 2 }}
								/>

								<CustomSelect
									name="productType"
									label="Proposed Funding Product"
									options={[
										{
											value: "short-term-input-finance",
											label: "Short Term Input Finance",
										},
										{ value: "invoice-finance", label: "Invoice Finance" },
										{
											value: "purchase-order-loan",
											label: "Purchase Order Loan",
										},
										{ value: "asset-finance", label: "Asset Finance" },
										{ value: "unspecified", label: "Unspecified" },
									]}
									value={formData.productType}
									onChange={handleSelectChange("productType")}
								/>

								<CustomSelect
									name="useOfFunds"
									label="Proposed Use of Funds"
									options={BusinessData().useOfFundsData.map((x: any) => ({
										label: x,
										value: x,
									}))}
									value={formData.useOfFunds}
									onChange={handleSelectChange("useOfFunds")}
									multiple
								/>

								<CustomSelect
									name="securityOrCollateral"
									label="Select security or collateral"
									options={[
										{
											value: "Contract -w/o Cession",
											label: "Contract - w/o Cession",
										},
										{
											value: "Asset(vehicle, machinery, etc)",
											label: "Asset (vehicle, machinery, etc)",
										},
										{ value: "Purchase Order", label: "Purchase Order" },
										{ value: "None", label: "None" },
									]}
									value={formData.securityOrCollateral}
									onChange={handleSelectChange("securityOrCollateral")}
								/>
							</Box>
						</BaseCard>

						<BaseCard sx={{ padding: "20px", height: "auto" }}>
							<Box component="form" mt={0}>
								<CustomCardHeader title="Additional Information" />

								<CustomTextInput
									name="productTypeAdditionalContext"
									label="Provide additional context, if available."
									multiline
									rows={3}
									value={formData.productTypeAdditionalContext}
									onChange={handleInputChange}
									sx={{ mb: 2 }}
								/>

								<CustomTextInput
									name="useOfFundsAdditionalContext"
									label="Additional Use of Funds Context"
									multiline
									rows={3}
									value={formData.useOfFundsAdditionalContext}
									onChange={handleInputChange}
									sx={{ mb: 2 }}
								/>

								<CustomTextInput
									name="securityAdditionalContext"
									label="Security or Collateral Additional Context"
									multiline
									rows={3}
									value={formData.securityAdditionalContext}
									onChange={handleInputChange}
									sx={{ mb: 2 }}
								/>
							</Box>
						</BaseCard>
					</ResponsiveGrid>

					<Box
						mt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<CustomButton
							text="Cancel"
							onClick={onClose}
							variant="outlined"
							color="secondary"
							sx={{ mr: 2 }}
						/>

						{isLoading ? (
							<CircularProgress size="1rem" color="success" />
						) : (
							<CustomButton
								text="Save"
								onClick={handleSubmit}
								variant="contained"
								color="success"
							/>
						)}
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default DuplicateFundingNeedModal;
