import React, { useState } from "react";
import {
	Box,
	Modal,
	Typography,
	useTheme,
	CircularProgress,
	IconButton,
} from "@mui/material";
import toast from "react-hot-toast";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import { Close } from "@mui/icons-material";
import { updateUserAndBusinessProfile } from "../../services/retool-service";
import { useQueryClient } from "react-query";

interface UpdateProfileModalProps {
	open: boolean;
	onClose: () => void;
	client?: any;
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({
	open,
	onClose,
	client,
}) => {
	const theme = useTheme();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState(client?.email || "");
	const [idNumber, setIdNumber] = useState(client?.idNumber || "");
	const [companyRegistrationNo, setCompanyRegistrationNo] = useState(
		client?.companyRegistrationNo || ""
	);

	const queryClient = useQueryClient();

	const handleUpdateProfile = async () => {
		setIsLoading(true);
		const toastId = toast.loading("Updating profile...");

		try {
			const payload = {
				userId: client?.userId,
				email,
				companyRegNumber: companyRegistrationNo,
				idNumber,
			};

			const response = await updateUserAndBusinessProfile(payload);
			const { message } = response.data;

			toast.success(message || "Profile updated successfully!", {
				id: toastId,
			});
			queryClient.invalidateQueries("clients");
		} catch (error: any) {
			toast.error(error?.response?.data?.message || "An error occurred.", {
				id: toastId,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					overflowY: "auto",
					position: "relative",
					top: "30%",
				}}
			>
				<IconButton
					sx={{
						position: "absolute",
						top: "16px",
						right: "16px",
						color: theme.palette.grey[500],
					}}
					onClick={onClose}
				>
					<Close />
				</IconButton>

				<Typography
					variant="h5"
					mb={4}
					sx={{ color: theme.palette.text.primary }}
				>
					Update Profile
				</Typography>

				<CustomTextInput
					name="email"
					label="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<CustomTextInput
					name="companyRegistrationNo"
					label="Company Registration No"
					value={companyRegistrationNo}
					onChange={(e) => setCompanyRegistrationNo(e.target.value)}
					sx={{ mb: 2 }}
				/>
				<CustomTextInput
					name="idNumber"
					label="ID Number"
					value={idNumber}
					onChange={(e) => setIdNumber(e.target.value)}
					sx={{ mb: 2 }}
				/>

				<Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
					{isLoading ? (
						<CircularProgress size={24} color="success" />
					) : (
						<CustomButton
							text="Update Profile"
							onClick={handleUpdateProfile}
							variant="contained"
						/>
					)}
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateProfileModal;
