import * as React from "react";
import {
	createTheme,
	ThemeProvider,
	useColorScheme,
} from "@mui/material/styles";
import {
	useNavigate,
	BrowserRouter as Router,
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout as ToolpadDashboardLayout } from "@toolpad/core/DashboardLayout";
import logo from "../../assets/images/pumpkn-full-logo-green.png";
import { ComponentRegistryProvider } from "../../../contexts/componentRegistryContext";
import { Toaster } from "react-hot-toast";
import { NAVIGATION } from "../../../configs/navigation";
import { dashboardTheme } from "../../../configs/dashboardTheme";
import DemoPageContent from "./DemoPageContent";
import AuthenticationRoutes from "../../../routes/auth";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, persistor } from "../../../app/store";
import { RootState } from "../../../app/store";
import { WHITELISTED_ROUTES } from "../../../routes/whitelist";
import { NO_LAYOUT_ROUTES } from "../../../routes/nolayout";
import { QueryClient, QueryClientProvider, useIsFetching } from "react-query";
import { logout } from "../../../common/features/auth/authSlice";
import toast from "react-hot-toast";

import CssBaseline from "@mui/material/CssBaseline";
import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			refetchOnReconnect: "always",
			refetchOnMount: "always",
		},
	},
});

interface LoadingProviderProps {
	children: React.ReactNode;
}

const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
	const isFetching = useIsFetching();
	const toastId = React.useRef<string | null>(null);

	React.useEffect(() => {
		if (isFetching > 0) {
			if (!toastId.current) {
				toastId.current = toast.loading("Loading...");
			}
		} else if (toastId.current) {
			toast.dismiss(toastId.current);
			toastId.current = null;
		}
	}, [isFetching]);

	return <>{children}</>;
};

const DashboardLayout: React.FC = () => {
	const [isDarkMode, setIsDarkMode] = React.useState(false);
	const [signout, setSignout] = React.useState(false);
	const [isLightMode, setIsLightMode] = React.useState(false);
	const navigate = useNavigate();
	const auth = useSelector((state: RootState) => state.auth);
	const [checked, setChecked] = React.useState(false);
	const location = useLocation();
	const { setMode, mode } = useColorScheme();

	React.useEffect(() => {
		if (auth.isAuthenticated) {
			setMode("light");
			setIsDarkMode(true);
		} else {
			setMode("light");
			setIsDarkMode(false);
		}
		setChecked(true);
	}, [auth.isAuthenticated, setMode]);

	React.useEffect(() => {
		if (
			!auth.isAuthenticated &&
			!WHITELISTED_ROUTES.includes(location.pathname)
		) {
			setMode("light");
			navigate("/authentication/login");
		} else {
			setChecked(true);
		}
	}, [auth.isAuthenticated, navigate, location.pathname]);

	const router = React.useMemo(() => {
		return {
			pathname: location.pathname,
			searchParams: new URLSearchParams(location.search),
			navigate: (path: string | URL) => navigate(path.toString()),
		};
	}, [location.pathname, location.search, navigate]);

	const [session, setSession] = React.useState<any | null>({
		user: {
			name: auth?.user?.name,
			email: auth?.user?.email,
			image: auth?.user?.avatar,
		},
	});

	const dispatch = useDispatch<AppDispatch>();

	const authentication = React.useMemo(() => {
		return {
			signIn: () => {
				setSession({
					user: {
						name: auth?.user?.name,
						email: auth?.user?.email,
						image: auth?.user?.avatar,
					},
				});
			},
			signOut: async () => {
				setMode("light");
				setIsDarkMode(false);
				setSignout(true);
			},
		};
	}, [auth]);

	React.useEffect(() => {
		const handleSignOut = async () => {
			if (signout && !isDarkMode) {
				setSession(null);
				await dispatch(logout());
			}
		};

		handleSignOut();
	}, [signout, isDarkMode, dispatch]);

	if (!checked) {
		return null;
	}

	return (
		<>
			<AppProvider
				navigation={NAVIGATION}
				branding={{ logo: <img src={logo} alt="Pumpkn logo" />, title: "" }}
				router={router}
				authentication={authentication}
				session={session}
				theme={createTheme({
					cssVariables: {
						colorSchemeSelector: "data-toolpad-color-scheme",
					},
					colorSchemes: { light: true, dark: isDarkMode },
				})}
			>
				<ToolpadDashboardLayout>
					<ComponentRegistryProvider>
						<DemoPageContent />
					</ComponentRegistryProvider>
				</ToolpadDashboardLayout>
				<Toaster position="top-center" />
			</AppProvider>
		</>
	);
};

const NoLayout: React.FC = () => {
	const navigate = useNavigate();
	const auth = useSelector((state: RootState) => state.auth);
	const [checked, setChecked] = React.useState(false);
	const location = useLocation();
	const { setMode, mode } = useColorScheme();

	React.useEffect(() => {
		setMode("light");
	}, [setMode]);

	localStorage.setItem("toolpad-mode", "light");

	React.useEffect(() => {
		if (
			auth.isAuthenticated &&
			WHITELISTED_ROUTES.includes(location.pathname)
		) {
			navigate("/review/manage-clients");
		} else {
			setChecked(true);
			setMode("light");
		}
	}, [auth.isAuthenticated, navigate, location.pathname]);

	if (!checked) {
		return null;
	}

	return (
		<ThemeProvider theme={{ mode: mode }}>
			<AuthenticationRoutes />
		</ThemeProvider>
	);
};

export default function DashboardApp() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<LoadingProvider>
						<Router>
							<Routes>
								<Route path="*" element={<DashboardWrapper />} />
							</Routes>
						</Router>
					</LoadingProvider>
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
}

const DashboardWrapper = () => {
	const location = useLocation();
	const useLayout = !NO_LAYOUT_ROUTES.includes(location.pathname);
	return useLayout ? <DashboardLayout /> : <NoLayout />;
};
